<template>
  <div>
    <Tabbar :placeholder="true" :safe-area-inset-bottom="true" route>
      <TabbarItem v-for="(item, index) in tabbarList" :key="index" replace :to="item.to" :icon="item.icon">{{ item.name }}</TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'
import { mapState } from 'vuex'
export default {
  name: 'MobileTabbar',
  components: {
    Tabbar,
    TabbarItem
  },
  computed: {
    ...mapState('mConfig', ['tabbarList'])
  }
}
</script>

<style scoped>

</style>
